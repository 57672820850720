// components/Posts.js
import React from 'react';
import { Link } from 'react-router-dom';

import { posts } from '../content/blogData';



const Posts = () => {

    return (
        <div>
          <h1>/posts</h1>
          <ul>
            {posts.map((post) => (
              <li key={post.id}>
                <Link to={`/post/${post.id}`}>{post.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      );
};

export default Posts;
