import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <h2>/root</h2>

      <p class="lead">Hello!</p>

      <p class="mb-4">I'm a software engineer by trade, and as any good software engineer, one must have their own website! This website serves as a place to collect and share my thoughts</p>
      <p> See the <Link to='/posts'>posts here</Link>, notably <Link to='/post/ai'>my AI journey </Link> & and my <Link to='/post/eng-reads'>recommended engineering reads</Link> </p>
     
      <p> Get in touch with me by sending an email to <a href="mailto:mail@vicariousjake.com">mail@vicariousjake.com</a></p>
    </div>
  );
};

export default Home;
