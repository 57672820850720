export const posts = [
  {
    id: "ai",
    title: `AI Journey`,
    content: `Why am I excited? Because this is the next wave of computing. Much has been written about how this is the next wave of computing & my own experiments have convinced me as such.  Some recent reading:
- [The Age of AI has begun | Bill Gates](https://www.gatesnotes.com/The-Age-of-AI-Has-Begun)
- [Cheating is All You Need](https://about.sourcegraph.com/blog/cheating-is-all-you-need)
- [Why This AI Moment May Be the Real Deal — The New Atlantis](https://www.thenewatlantis.com/publications/why-this-ai-moment-may-be-the-real-deal)

Hopefully that got you somewhat excited.  I'm maintaining a list of my thoughts and resources here as I explore more.  

`
  },
  {
    id: "eng-reads",
    title: `Engineering Must-Reads`,
    content: `Articles and Books that have shaped my perspective and understanding of software engineering.  This is a living list and I'll keep updating it!
## Articles
- [How to Misuse Code Coverage](http://www.exampler.com/testing-com/writings/coverage.pdf)
  - The fallacies that comes from chasing that 100% number!  As with all things, think about what it is _doing_. 


## Books:

For a new graduates
  - Intro to many basic concepts:  A Philosophy of Software Design by John Ousterhout

For System design:
 - Designing Data-Intensive Applications by  Martin Kleppmann

`,
  },
  {
    id: 0o0001,
    title: `We're live!`,
    content: `Otherwise, in classic programmer lingo, known as:
## Hello World!
With ChatGPT's assist I threw this together in under two hours. My 9-to-5 doesn't leave much AI exploration, so here's my public AI playpen. Built on React, dolled up with styling, and filled with markdown formatted content, all live via Vercel. 

So, why am I putting all this out here? Well, swyx's got this idea about "Learning in public". I'm all in for speeding up my AI journey and sharing tips with the world. Let's dive into this learning adventure together – check out swyx's wisdom for yourself: https://www.swyx.io/learn-in-public!
`,
  },
  // Add more posts here if needed
];
