import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav>
      <div class="container">
        <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <div class="col-md-3 mb-2 mb-md-0">
          <Link to ="/" class="d-inline-flex link-body-emphasis text-decoration-none align-items-center">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" width="40" height="40" />
          <span class="px-2">Vicarious Jake</span>
          </Link>
        </div>

        <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
          <li><Link to='/' class="nav-link px-2 link-secondary">/Root</Link></li>
          <li><Link to='/posts' class="nav-link px-2">/Posts</Link></li>
        </ul>

      </header>
      </div>
    </nav>
  );
};

export default Header;