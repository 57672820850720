import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import Posts from './components/all-posts';
import Post from './components/post';
import Header from "./components/header"




const App = () => {
  return (
    <Router>
      <Header /> 

      <div class="container">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/posts" exact element={<Posts />} />
          <Route path="/post/:postId" element={<Post />} />
        </Routes>
      </div>

    </Router>
  );
};

export default App;
