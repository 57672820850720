// components/Posts.js
import React from 'react';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

import { useParams } from 'react-router-dom';
import { posts } from '../content/blogData';



const Post = () => {


    const { postId } = useParams();
    const post = posts.find((post) => post.id.toString() === postId);

    if (!post) {
        return <div>Post not found!</div>;
    }

    return (
        <div>
        <h1>{post.title}</h1>
        <ReactMarkdown children={String(post.content)} remarkPlugins={[remarkGfm]} />
        </div>
    );
};

export default Post;

